@font-face {
    font-family: "SST W20 Latin";
    src: url("/presscentre/fonts/SSTW20-UltraLight.woff2") format("woff2"),
        url("/presscentre/fonts/SSTW20-UltraLight.woff") format("woff");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "SST W20 Latin";
    src: url("/presscentre/fonts/SSTW20-UltraLightItalic.woff2") format("woff2"),
        url("/presscentre/fonts/SSTW20-UltraLightItalic.woff") format("woff");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "SST W20 Latin";
    src: url("/presscentre/fonts/SSTW20-Light.woff2") format("woff2"),
        url("/presscentre/fonts/SSTW20-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "SST W20 Latin";
    src: url("/presscentre/fonts/SSTW20-LightItalic.woff2") format("woff2"),
        url("/presscentre/fonts/SSTW20-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "SST W20 Latin";
    src: url("/presscentre/fonts/SSTW20-Roman.woff2") format("woff2"),
        url("/presscentre/fonts/SSTW20-Roman.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "SST W20 Latin";
    src: url("/presscentre/fonts/SSTW20-Italic.woff2") format("woff2"),
        url("/presscentre/fonts/SSTW20-Italic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "SST W20 Latin";
    src: url("/presscentre/fonts/SSTW20-Medium.woff2") format("woff2"),
        url("/presscentre/fonts/SSTW20-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "SST W20 Latin";
    src: url("/presscentre/fonts/SSTW20-MediumItalic.woff2") format("woff2"),
        url("/presscentre/fonts/SSTW20-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "SST W20 Latin";
    src: url("/presscentre/fonts/SSTW20-Bold.woff2") format("woff2"),
        url("/presscentre/fonts/SSTW20-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "SST W20 Latin";
    src: url("/presscentre/fonts/SSTW20-BoldItalic.woff2") format("woff2"),
        url("/presscentre/fonts/SSTW20-BoldItalic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "SST W20 Latin";
    src: url("/presscentre/fonts/SSTW20-Heavy.woff2") format("woff2"),
        url("/presscentre/fonts/SSTW20-Heavy.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "SST W20 Latin";
    src: url("/presscentre/fonts/SSTW20-HeavyItalic.woff2") format("woff2"),
        url("/presscentre/fonts/SSTW20-HeavyItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "SST W20 Latin Condensed";
    src: url("/presscentre/fonts/SSTW20-Condensed.woff2") format("woff2"),
        url("/presscentre/fonts/SSTW20-Condensed.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "SST W20 Latin Condensed";
    src: url("/presscentre/fonts/SSTW20-CondensedBd.woff2") format("woff2"),
        url("/presscentre/fonts/SSTW20-CondensedBd.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}
